@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.List {
  display: grid;
  grid-template-columns: repeat(auto-fill, 247px);
  justify-content: space-between;
}

.Item {
  position: relative;
  width: 248px;
  height: 310px;
  margin-bottom: 30px;
  font-family: 'avante-regular', sans-serif;
  font-size: 13px;
  line-height: 19px;
  color: $color_white-base;
  cursor: pointer;
  background-size: 100%;
  .Description {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 15px;
    background: $color_black-base;
    .Top,
    .Bottom {
      display: flex;
      justify-content: space-between;
    }
    .Bottom {
      margin-top: 4px;
    }
  }
  .Title {
    font-size: 17px;
    line-height: 19px;
  }
  .Price {

    color: $color_yellow-base;
  }
  .Category {
    opacity: 0.5;
  }
  .CountPlayers {
    position: relative;
    padding-right: 20px;
    &::after {
      content: '';
      position: absolute;
      top: 4px;
      right: 0;
      width: 16px;
      height: 10px;
      background-image: url('../../assets/images/locationPage/count-players.svg');
    }
  }
}

@include respond-to($desktop) {
  .List {
    grid-template-columns: repeat(auto-fill, 217px);
  }
  .Item {
    width: 218px;
    height: 218px;
    margin-bottom: 26px;
    font-size: 11px;
    line-height: 19px;
  }
}

@media (min-width: 860px) and (max-width: 1024px) {
  .List {
    grid-template-columns: repeat(auto-fill, 251px);
  }
  .Item {
    margin: 0;
    margin-bottom: 27px;
  }
}

@media (min-width: 830px) and (max-width: 859px) {
  .List {
    grid-template-columns: repeat(auto-fill, 248px);
  }
}

@media (min-width: 768px) and (max-width: 829px) {
  .List {
    grid-template-columns: repeat(auto-fill, 310px);
  }
  .Item {
    width: 310px;
  }
}

@include respond-to($mobile) {
  .List {
    .Item {
      width: 100%;
      background-position-y: -40px;
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .List {
    display: flex;
    flex-direction: column;
    align-items: center;
    .Item {
      width: 300px;
    }
  }
}