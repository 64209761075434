@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.MainHeader {
  position: absolute;
  left: 50%;
  margin-left: -680px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: $desktop-lg-width;
  padding-top: 40px;
  z-index: 102;
  .Wrapper {
    display: flex;
    align-items: center;
  }
}

@include respond-to($desktop) {
  .MainHeader {
    margin-left: -477px;
    width: $desktop-width;
    padding-top: 35px;
  }
}

@include respond-to($tablet) {
  .MainHeader {
    left: 0;
    min-width: $tablet-width - 1;
    width: 100%;
    margin-left: 0;
    padding: 0 4.5%;
    padding-top: 20px;
    nav {
      &.DesktopOnly {
        display: none;
      }
    }
    button {
      position: absolute;
      top: 370px;
      left: 50%;
      transform: translate(15%, 0);
    }
  }
}

@include respond-to($mobile) {
  .MainHeader {
    left: 0;
    min-width: $mobile-width;
    width: 100%;
    margin-left: 0;
    padding: 0 3%;
    padding-top: 18px;
    nav {
      &.DesktopOnly {
        display: none;
      }
    }
    button {
      position: absolute;
      top: 255px;
      right: 3%;
    }
  }
}

@media (min-width: 585px) and (max-width: 767px) {
  .MainHeader {
    button {
      top: 240px;
    }
  }
}
