@import '../../assets/scss/mixins';

.Logo {
  cursor: pointer;
  img {
    width: 143px;
    height: 33px;
  }
}

@include respond-to($desktop) {
  .Logo {
    img {
      width: 121px;
      height: 28px;
    }
  }
}

@include respond-to($mobile) {
  .Logo {
    img {
      width: 83px;
      height: 19px;
    }
  }
}