@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Wrapper {
  background-color: $main-bg-color;
  .Container {
    @include desktop-lg-container();
    padding-top: 120px;
    .Content {
      margin-bottom: 66px;
      .Back {
        display: none;
      }
    }
  }
}

@include respond-to($desktop) {
  .Wrapper {
    .Container {
      width: $desktop-width;
      padding-top: 105px;
      .Content {
        margin-bottom: 141px;
      }
    }
  }
}

@include respond-to($tablet) {
  .Wrapper {
    .Container {
      min-width: $tablet-width;
      width: 91%;
      padding-top: 100px;
    }
  }
}

@include respond-to($mobile) {
  .Wrapper {
    .Container {
      min-width: $mobile-width;
      width: 100%;
      padding-top: 78px;
      .Content {
        padding: 0 10px;
        .Back {
          display: block;
        }
      }
      button {
        display: none;
      }
    }
  }
}