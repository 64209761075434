@import './variables';

$mobile: '(min-width: 320px) and (max-width: 767px)';
$tablet: '(min-width: 768px) and (max-width: 1024px)';
$desktop: '(min-width: 1025px) and (max-width: 1439px)';
$desktop-lg: '(min-width: 1440px)';

@mixin respond-to($media) {
	@media screen and #{$media} {
		@content;
	}
}

@mixin desktop-lg-container {
	width: $desktop-lg-width;
	margin: 0 auto;
}

@mixin desktop-container {
	width: $desktop-width;
	margin: 0 auto;
}

@mixin tablet-container {
	width: 100%;
	padding: 0 4.5%;
}

@mixin mobile-container {
	width: 100%;
	padding: 0 3%;
}