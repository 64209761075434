@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

$font-size: 13px;
$line-height: 1.5;
$lines-to-show: 3;

.List {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.Item {
  width: 425px;
  margin-bottom: 47px;
  padding: 20px 25px 27px 20px;
  font-family: 'avante-regular', sans-serif;
  color: $color_white-base;
  background: $item-bg;
  cursor: pointer;
  .Time {
    font-size: 15px;
    line-height: 18px;
    span {
      color: $color_yellow-base;
    }
  }
  .Poster {
    margin: 15px 0;
    img {
      width: 380px;
    }
  }
  .Title {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 22px;
  }
  .Description {
    display: block;
    display: -webkit-box;
    height: $font-size*$line-height*$lines-to-show;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    a {
      color: $color_yellow-base;
    }
  }
  .About {
    font-size: 13px;
    line-height: 20px;
    opacity: 0.4;
  }
}

.ModalContent {
  position: relative;
  color: $color_white-base;
  .Poster {
    margin-top: 20px;
    margin-bottom: 25px;
    img {
      width: 100%;
    }
  }
  .Title {
    margin-bottom: 10px;
  }
  .Scroll {
    position: relative;
    min-height: auto;
    max-height: 79vh;
    overflow: scroll;
  }
  .Description {
    font-size: 13px;
    line-height: 20px;
    opacity: 0.5;
    a {
      color: $color_yellow-base;
    }
  }
  .ModalClose {
    position: absolute;
    top: 0;
    right: 0;
    img {
      cursor: pointer;
    }
  }
}

@include respond-to($desktop) {
  .Item {
    width: 300px;
    padding: 20px;
    &:nth-child(3n+2) {
      margin-left: 27px;
      margin-right: 27px;      
    }
    .Poster {
      margin: 10px 0;
      img {
        width: 100%;
      }
    }
  }
}

@include respond-to($tablet) {
  .Slider {
    width: 65vw;
    margin: 0 auto;
    .Item {
      .Poster {
        img {
          width: 100%;
        }
      }
    }
  }

  .List {
    justify-content: space-between;
    .Item {
      width: 48%;
      &:nth-child(3n+2) {
        margin-left: 0;
        margin-right: 0;      
      }
      .Poster {
        img {
          width: 100%;
        }
      }
    }
  }
}

@include respond-to($mobile) {
  .NewsList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    .Item {
      width: 48%;
      &:nth-child(3n+2) {
        margin-left: 0;
        margin-right: 0;      
      }
      .Poster {
        img {
          width: 100%;
        }
      }
      .Description {
        height: auto;
        overflow: initial;
        text-overflow: initial;
        -webkit-line-clamp: initial;
      }
    }
  }

  .List {
    margin-top: 20px;
    flex-wrap: nowrap;
    flex-direction: column;
    .Item {
      width: 100% !important;
      margin-bottom: 0;
      padding: 16px;
      padding-bottom: 18px;
      &.Even {
        margin: 0;
      }
      .Poster {
        img {
          width: 100%;
        }
      }
      .Title {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .slick-arrow {
      display: none;
    }
  }
}

@media (min-width: 320px) and (max-width: 570px) {
  .NewsList {
    .Item {
      width: 100%;
    }
  }
}