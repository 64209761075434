@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Modal {
  position: fixed;
  background-color: $main-bg-color;
  width: 696px;
  padding: 30px;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  &.SmallWidth {
    width: 470px;
    padding: 20px;
  }
}

@include respond-to($tablet) {
  .Modal {
    width: $tablet-width;
  }
}

@include respond-to($mobile) {
  .Modal {
    width: $mobile-width;
    padding: 25px 13px 30px 13px;
    &.SmallWidth {
      width: $mobile-width;
    }
  }
}