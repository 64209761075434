@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.List {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.Item {
  width: 422px;
  margin-bottom: 47px;
  padding: 20px 26px 26px 21px;
  font-family: 'avante-regular', sans-serif;
  background: $item-bg;
  color: $color_white-base;
  // .Name {
  //   margin-bottom: 5px;
  //   font-size: 13px;
  //   line-height: 15px;
  //   color: $gray_color;
  //   opacity: 0.4;
  // }
  .How {
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 18px;
  }
  .Poster {
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }
  .Title {
    margin-bottom: 7px;
    font-size: 16px;
    line-height: 22px;
  }
  .Description {
    margin-bottom: 22px;
    font-size: 13px;
    line-height: 20px;
    color: $gray_color;
    opacity: 0.4;
  }
  // .About {
  //   font-size: 13px;
  //   line-height: 20px;
  //   span {
  //     color: $color_yellow-base;
  //   }
  // }
}

.ShowMore {
  text-align: center;
  color: $color_white-base;
  cursor: pointer;
}

@include respond-to($desktop) {
  .Item {
    width: 300px;
    padding: 14px 12px 15px 12px;
    margin-bottom: 33px;
    .Name {
      font-size: 9px;
      line-height: 10px;
    }
    .How {
      font-size: 10px;
      line-height: 13px;
    }
    .How,
    .Poster {
      margin-bottom: 11px;
    }
    .Title {
      margin-bottom: 5px;
      font-size: 11px;
      line-height: 15px;
    }
    .Description {
      margin-bottom: 15px;
    }
    .Description,
    .About {
      font-size: 9px;
      line-height: 14px;
    }
  }
}

@include respond-to($tablet) {
  .Item {
    width: 48%;
    margin-bottom: 30px;
  }
}

@include respond-to($mobile) {
  .List {
    margin-top: 20px;
  }
  .Item {
    width: 48%;
    margin-bottom: 30px;
    padding: 16px;
    .Wrapper {
      display: flex;
    }
    .Name {
      margin-right: 4px;
    }
    .Name,
    .How {
      font-size: 15px;
      line-height: 18px;
    }
    .Poster {
      margin-bottom: 13px;
    }
    .Description,
    .About {
      font-size: 12px;
    }
    .About {
      width: 80%;
    }
  }
}

@media (min-width: 320px) and (max-width: 569px) {
  .List {
    justify-content: center;
  }
  .Item {
    width: $mobile-width;
  }
}