@import '../../assets/scss/mixins';

.Features {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 75px;
}

@include respond-to($desktop) {
  .Features {
    margin-bottom: 70px;
  }
}

@include respond-to($tablet) {
  .Features {
    display: none;
  }
}

@include respond-to($mobile) {
  .Features {
    display: none;
  }
}