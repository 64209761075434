@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Investments {
  padding-top: 115px;
  padding-bottom: 80px;
  background: $black-color;

  .Container {
    @include desktop-lg-container();
  }

  .Top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Left {
      h3 {
        width: 330px;
        margin-bottom: 30px;
      }

      p {
        margin-bottom: 30px;
        line-height: 1.5;
      }

      .Items {
        .Item {
          position: relative;
          margin-bottom: 20px;
          padding-left: 30px;
          font-family: 'avante-regular', sans-serif;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 15px;
            height: 14px;
            background: url('../../assets/images/franchizePage/tick.svg') no-repeat;
          }
        }
      }

      .Summary {
        margin-top: 30px;
        margin-bottom: 0;
        font-family: 'avante-regular', sans-serif;
        font-size: 22px;

        span {
          color: $color_yellow-base;
        }
      }
    }

    .Right {
      img {
        width: 835px;
      }
    }
  }

  .Center {
    margin-top: 90px;
    margin-bottom: 85px;

    .Features {
      display: flex;
      justify-content: space-between;
      padding: 0 110px;

      .Feature {
        max-width: 260px;
        text-align: center;

        .Title {
          margin-bottom: 15px;
          font-family: 'bebas-bold', sans-serif;
          font-size: 32px;
          line-height: 1.3;
          color: $color_yellow-base;
        }

        .Description {
          font-family: 'avante-regular', sans-serif;
          font-size: 18px;
          line-height: 1.5;
        }
      }
    }
  }

  .Results {
    text-align: center;
    margin-bottom: 90px;
  }
}

@include respond-to($desktop) {
  .Investments {
    .Container {
      @include desktop-container();
    }

    .Top {
      .Right {
        img {
          width: 550px;
        }
      }
    }

    .Center {
      .Features {
        padding: 0;
      }
    }
  }
}

@include respond-to($tablet) {
  .Investments {
    .Container {
      @include tablet-container();
    }

    p {
      text-align: center;
    }

    .Top {
      flex-direction: column;
      .Left {
        margin-bottom: 30px;
        
        h3 {
          width: 100%;
        }

        .Items {
          padding-left: 50px;
        }
      }

      .Right {
        img {
          width: 100%;
        }
      }
    }

    .Center {
      margin: 50px 0;

      .Features {
        padding: 0;

        .Feature {
          max-width: 24%;
          .Title {
            font-size: 30px;
          }

          .Description {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@include respond-to($mobile) {
  .Investments {
    padding: 40px 0;

    .Container {
      @include mobile-container();
    }

    .Top {
      flex-direction: column;
      align-items: baseline;

      .Left {
        margin-bottom: 30px;

        h3 {
          width: 100%;
        }

        .Summary {
          font-size: 19px;
        }
      }

      .Right {
        img {
          width: 100%;
        }
      }
    }

    .Center {
      margin: 0;
      margin-top: 40px;

      .Features {
        flex-direction: column;
        align-items: center;
        padding: 0;

        .Feature {
          max-width: 100%;
          margin-bottom: 50px;

          &:last-child {
            margin-bottom: 0;
          }

          .Title {
            font-size: 28px;
          }

          .Description {
            font-size: 14px;
          }
        }
      }
    }

    .Results {
      margin: 0;
      margin-top: 50px;
    }
  }
}