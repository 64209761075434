@import '../../assets/scss/mixins';

.List {
  display: flex;
  flex-wrap: wrap;
}

@include respond-to($desktop) {
  .List {
    width: 75%;
  }
}

@include respond-to($tablet) {
  .List {
    justify-content: space-between;
  }
}

@media (min-width: 768px) and (max-width: 940px) {
  .List {
    display: grid;
    grid-template-columns: repeat(auto-fill, 165px);
  }
}

@include respond-to($mobile) {
  .List {
    display: grid;
    grid-template-columns: repeat(auto-fill, 133px);
    justify-content: space-between;
  }
}

@media (min-width: 320px) and (max-width: 424px) {
  .List {
    display: flex;
  }
}