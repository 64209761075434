@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Team {
  padding-bottom: 160px;
  background-color: $franchize-bg;

  .Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include desktop-lg-container();
  }

  .Left {
    h3 {
      margin-bottom: 55px;
    }

    .Wrapper {
      position: relative;
      width: 670px;
      padding-left: 100px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 51px;
        height: 42px;
        background: url('../../assets/images/franchizePage/quotes.svg') no-repeat;
        background-size: cover;
      }

      .Description {
        margin-bottom: 35px;
        font-family: 'avante-regular', sans-serif;
        font-size: 17px;
        line-height: 1.5;
        opacity: 0.8;
      }

      .InnerWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Name {
          margin-bottom: 5px;
          font-family: 'avante-regular', sans-serif;
          font-size: 20px;
          line-height: 1.5;
        }

        .Role {
          opacity: 0.5;
        }
      }
    }
  }

  .Right {
    img {
      width: 500px;
    }
  }
}

@include respond-to($desktop) {
  .Team {
    .Container {
      @include desktop-container();
    }

    .Left {
      .Wrapper {
        width: 500px;
        padding-left: 90px;

        .Description {
          font-size: 16px;
        }

        .InnerWrapper {
          .Name {
            font-size: 18px;
          }
        }
      }
    }

    .Right {
      img {
        width: 410px;
      }
    }
  }
}

@include respond-to($tablet) {
  .Team {
    padding-bottom: 100px;

    .Container {
      @include tablet-container();
      flex-direction: column;
    }

    .Left {
      margin-bottom: 40px;

      h3 {
        text-align: center;
      }

      .Wrapper {
        width: 100%;

        .Description {
          font-size: 16px;
        }

        .InnerWrapper {
          .Name {
            font-size: 18px;
          }
        }
      }
    }

    .Right {
      img {
        width: 100%;
      }
    }
  }
}

@include respond-to($mobile) {
  .Team {
    padding: 40px 0;

    .Container {
      @include mobile-container();
      flex-direction: column;
    }

    .Left {
      width: 100%;
      margin-bottom: 40px;

      h3 {
        margin-bottom: 35px;
      }

      .Wrapper {
        width: 100%;
        padding-left: 75px;

        .Description {
          font-size: 14px;
        }

        .InnerWrapper {
          .Name {
            font-size: 16px;
          }

          .Role {
            font-size: 14px;
          }
        }
      }
    }

    .Right {
      img {
        width: 100%;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 450px) {
  .Team {
    .Left {
      .Wrapper {
        padding-left: 0;

        &::before {
          content: none;
        }
      }
    }
  }
}