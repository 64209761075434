@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: $main-bg-color;
  .Container {
    width: $desktop-lg-width;
    z-index: 101;
  }
  .Content {
    display: flex;
    justify-content: space-between;
    margin-top: 132px;
    margin-bottom: 96px;
  }
  .Left {
    width: 100%;
  }
  .Top {
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
    margin-bottom: 33px;
  }
  .Right {
    height: 100%;
    padding: 24px;
    padding-top: 27px;
    background-color: $board_games-bg-color;
  }
  .BoardGamesTitle {
    margin-bottom: 28px;
    font-family: 'avante-regular', sans-serif;
    font-size: 17px;
    line-height: 20px;
    color: $color_white-base;
  }
}

.ModalContent {
  display: flex;
  .ModalLeft {
    margin-right: 32px;
    img {
      width: 185px;
      height: 250px;
    }
  }

  .ModalRight {
    padding-right: 37px;
    .ModalTitle {
      margin-bottom: 26px;
      font-family: 'avante-regular', sans-serif;
      font-size: 20px;
      line-height: 23px;
      color: $color_white-base;

    }
    .ModalDescription {
      margin-bottom: 57px;
      font-family: 'avante-regular', sans-serif;
      font-size: 13px;
      line-height: 20px;
      color: $color_white-base;
      opacity: 0.5;
    }
    .ModalConsole,
    .ModalJoystick,
    .ModalAvailable {
      margin-bottom: 12px;
      font-family: 'avante-regular', sans-serif;
      font-size: 12px;
      line-height: 14px;
      color: $board_games-color;
      span {
        color: $color_white-base;
      }
    }
    .ModalAvailable {
      margin-bottom: 0;
    }
  }

  .ModalClose {
    img {
      position: relative;
      top: -3px;
      right: 0;
      cursor: pointer;
    }
  }
}

@include respond-to($desktop) {
  .Wrapper {
    .Container {
      width: $desktop-width;
    }
    .Content {
      position: relative;
      margin-top: 105px;
    }
    .Top {
      margin-right: 0;
    }
    .Right {
      position: absolute;
      top: 105px;
      right: 0;
      height: auto;
      padding: 20px;
      padding-top: 21px;
    }
    .BoardGamesTitle {
      margin-bottom: 22px;
      font-size: 13px;
      line-height: 16px;
    }
  }
}

@include respond-to($tablet) {
  .Wrapper {
    .Container {
      min-width: $tablet-width;
      width: 91%;
    }
    .Content {
      margin-top: 100px;
      margin-bottom: 50px;
    }
    .Top {
      margin-right: 0;
    }
    .Right {
      display: none;
    }
  }
  .ModalContent {
    position: relative;
    flex-direction: column;
    .ModalLeft {
      margin: 0;
      text-align: center;
      img {
        width: 250px;
        height: auto;
      }
    }
    .ModalRight {
      padding-right: 0;
      .ModalTitle {
        margin-top: 22px;
        margin-bottom: 7px;
        text-align: center;
      }
      .ModalDescription {
        margin-bottom: 25px;
      }
    }
    .ModalClose {
      position: absolute;
      top: 0;
      right: 0;
      img {
        width: 17px;
        top: 0;
        right: 0;
      }
    }
  }
}

@include respond-to($mobile) {
  .Wrapper {
    .Container {
      min-width: $mobile-width;
      width: 94%;
      button {
        display: none;
      }
    }
    .Content {
      margin: 0;
      margin-top: 74px;
    }
    .Top {
      margin: 0;
      margin-bottom: 27px;
    }
    .Right {
      display: none;
    }
  }
  .ModalContent {
    position: relative;
    flex-direction: column;
    .ModalLeft {
      margin: 0;
      text-align: center;
    }
    .ModalRight {
      padding-right: 0;
      .Scroll {
        position: relative;
        min-height: auto;
        max-height: 200px;
        overflow: scroll;
      }
      .ModalTitle {
        margin-top: 22px;
        margin-bottom: 7px;
        text-align: center;
        font-size: 16px;
        line-height: 19px;
      }
      .ModalDescription {
        margin-bottom: 0;
        font-size: 12px;
      }
      .ModalJoystick {
        margin-top: 25px;
      }
    }
    .ModalClose {
      position: absolute;
      top: -10px;
      right: 0;
      img {
        width: 17px;
        top: 0;
        right: 0;
      }
    }
  }
}