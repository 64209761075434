@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.BonusSystem {
  padding: 60px 0;
  background: url('../../assets/images/mainPage/bonusSystem-bg.png') $main-bg-color no-repeat;
  background-size: 100% 100%;
  color: $color_white-base;
  .Container {
    @include desktop-lg-container();
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Left {
    position: relative;
    left: 115px;
    width: 270px;
    .Title {
      margin-bottom: 15px;
      font-family: 'bebas-bold', sans-serif;
      font-size: 40px;
      line-height: 48px;
      color: $color_white-base;
    }
    .Description {
      margin-bottom: 45px;
      font-family: 'avante-regular', sans-serif;
      font-size: 15px;
      line-height: 20px;
      opacity: 0.8;
    }
  }
  .Right {
    position: relative;
    left: 45px;
    img {
      width: 624px;
      height: auto;
    }
  }
}

@include respond-to($desktop) {
  .BonusSystem {
    .Container {
      width: $desktop-width;
    }
    .Left {
      .Description {
        font-size: 13px;
      }
    }
  }
}

@include respond-to($tablet) {
  .BonusSystem {
    .Container {
      flex-direction: column;
      width: $tablet-width;
    }
    .Left {
      position: static;
      order: 2;
      width: auto;
      margin-top: 45px;
    }
    .Right {
      position: static;
      order: 1;
      img {
        position: relative;
      }
    }
  }
}

@include respond-to($mobile) {
  .BonusSystem {
    padding: 25px 0 40px 0;
    background-size: 320%;
    .Container {
      flex-direction: column;
      min-width: $mobile-width;
      width: 65vw;
    }
    .Left {
      position: static;
      order: 2;
      width: auto;
      margin-top: 45px;
      .Title {
        margin-bottom: 5px;
        font-size: 35px;
        line-height: 42px;
      }
      .Description {
        margin-bottom: 22px;
        font-size: 13px;
        line-height: 20px;
      }
    }
    .Right {
      position: static;
      order: 1;
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 680px) {
  .BonusSystem {
    .Container {
      width: 94%;
    }
  }
}