@import '../../../assets/scss/mixins';

.FranchizeButton {
  button {
    padding: 13px 25px 10px 15px;

    &::after {
      right: 17px;
    }
  }
}

@include respond-to($desktop) {
  .FranchizeButton {
    button {
      padding: 10px 25px 10px 15px;

      &::after {
        right: 12px;
      }
    }
  }
}

@include respond-to($mobile) {
  .FranchizeButton {
    button {
      padding: 11px 25px 10px 15px;
    }
  }
}