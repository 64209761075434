@import '../node_modules/reset-css/sass/reset';
@import '../node_modules/react-datepicker/dist/react-datepicker.min.css';
@import '../node_modules/antd/dist/antd.min.css';
@import './assets/scss/fonts';
@import './assets/scss/variables';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $main-bg-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// react-slick
.slick-dots {
  li {
    width: 7px;
    &.slick-active {
      button::before {
        color: $color_white-base;
        opacity: 0.7;
      }
    }
    button::before {
      color: $color_white-base;
      opacity: 0.2;    
    }
  }
}

.slick-arrow {
  z-index: 1;
  &::before {
    content: none;
  }
}

.rdtPicker {
  background: $main-bg-color;
  color: $color_white-base;

  .rdtDay,
  .rdtPrev,
  .rdtSwitch,
  .rdtNext,
  .dow,
  .rdtTimeToggle {
    padding: 6px 0;
  }

  .rdtDay,
  .rdtPrev,
  .rdtNext,
  .rdtSwitch,
  .rdtTimeToggle,
  .rdtMonth,
  .rdtBtn {
    &:hover {
      color: $main-bg-color;
    }
  }

  th,
  td {
    height: auto;
  }
}