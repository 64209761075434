@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.LocationsSlider {
  .slick-arrow {
    top: 120%;
    width: 37px;
    height: 37px;
    &:hover {
      img {
        display: none;
      }
    }
    &.slick-prev {
      left: 395px;
      &:hover {
        background-image: url('../../assets/images/mainPage/slider-prev-arrow-hover.svg');
      }
    }
    &.slick-next {
      right: 395px;
      &:hover {
        background-image: url('../../assets/images/mainPage/slider-next-arrow-hover.svg');
      }
    }
  }
  .slick-dots {
    bottom: -85px;
    li {
      margin: 0 12px;
      &.slick-active {
        button {
          &::before {
            content: url('../../assets/images/mainPage/slider-active-dot.svg');
            color: $color_yellow-base;
          }
          &::after {
            content: '';
            position: absolute;
            top: -5px;
            left: -5px;
            width: 30px;
            height: 30px;
            background: #E7CF01;
            opacity: .1;
            border-radius: 50%;
            filter: blur(10px);
          }
        }
      }
    }
  }
}

@include respond-to($desktop) {
  .LocationsSlider {
    .slick-arrow {
      &.slick-prev {
        left: 260px;
      }
      &.slick-next {
        right: 260px;
      }
    }
    .slick-dots {
      bottom: -75px;
    }
  }
}

@include respond-to($tablet) {
  .LocationsSlider {
    .slick-arrow {
      display: none;
    }
    .slick-dots {
      bottom: -50px;
    }
  }
}