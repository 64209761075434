@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.SocialBtns {
  display: flex;
  position: relative;
  z-index: 3;
}

@include respond-to($tablet) {
  .SocialBtns {
    justify-content: center;
    padding-top: 35px;
    border-top: 1px solid $gray_color;
    opacity: 0.5;
  }
}

@include respond-to($mobile) {
  .SocialBtns {
    justify-content: center;
    padding-top: 35px;
    border-top: 1px solid $gray_color;
    opacity: 0.5;
  }
}