@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

::placeholder {
  color: #A6A6A6;
}

.Input {
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
  &.Invalid {
    border: 1px solid red;
  }
  .Label {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 18px;
    color: $color_white-base;
  }

  input {
    width: 208px;
    padding: 12px;
    font-size: 13px;
    line-height: 15px;
    border: 1px solid $input-bg-color;
    border-radius: 0px;
    background: $input-bg-color;
    color: $color_white-base;
    outline: none;
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .Input {
    margin-bottom: 20px;
    .Label {
      font-size: 14px;
      line-height: 16px;
    }
    input,
    select {
      width: 100%;
    }
    input {
      margin-bottom: 0;
    }
  }
}