@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Description {
  font-family: 'avante-regular', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: $color_white-base;
  opacity: 0.8;
}

@include respond-to($desktop) {
  .Description {
    font-size: 12px;
    line-height: 16px;
  }
}

@include respond-to($mobile) {
  .Description {
    font-size: 12px;
  }
}