@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Conditions {
  padding-top: 80px;
  padding-bottom: 100px;
  text-align: center;
  background-color: $franchize-bg;

  .Container {
    @include desktop-lg-container();
  }

  h3 {
    margin-bottom: 35px;
  }

  p {
    width: 600px;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  .Wrapper {
    display: flex;
    width: 1130px;
    margin: 0 auto;
    margin-bottom: 55px;
  }

  .LeftConditions,
  .RightConditions {
    display: flex;
    flex-direction: column;

    .Item {
      position: relative;
      width: 470px;
      margin-bottom: 30px;
      text-align: left;
      font-family: 'avante-regular', sans-serif;
      line-height: 1.5;
      opacity: 0.8;

      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: -15px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $color_yellow-base;
      }
    }
  }

  .LeftConditions {
    margin-right: 180px;
  }
}

@include respond-to($desktop) {
  .Conditions {
    .Container {
      @include desktop-container();
    }
  }
}

@include respond-to($tablet) {
  .Conditions {
    .Container {
      @include tablet-container();
    }
  }
}

@media (min-width: 768px) and (max-width: 1439px) {
  .Conditions {
    .Wrapper {
      justify-content: space-between;
      width: 96%;
    }

    .LeftConditions,
    .RightConditions {
      width: 45%;

      .Item {
        width: 100%;
      }
    }

    .LeftConditions {
      margin-right: 0;
    }
  }
}

@include respond-to($mobile) {
  .Conditions {
    padding: 40px 0;
    text-align: left;

    .Container {
      @include mobile-container();
    }

    p {
      width: 100%;
    }
    
    .Wrapper {
      flex-direction: column;
      width: auto;
      margin: 0;
      margin-left: 15px;
    }

    .LeftConditions,
    .RightConditions {
      width: 100%;

      .Item {
        width: auto;
        margin-bottom: 25px;
        font-size: 14px;
      }
    }

    .RightConditions {
      .Item {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .LeftConditions {
      margin-right: 0;
    }
  }
}