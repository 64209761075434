@import '../../../assets/scss/variables';

.time-picker {
  .react-datepicker-popper {
    .react-datepicker {
      &__header {
        display: none;
      }
      &__time {
        border-radius: 0;
        background: transparent;
        &-list {
          background: $input-bg-color;
          &-item {
            color: $color_white-base;
            &:hover {
              background: transparent !important;
              color: $color_yellow-base;
            }
            &--disabled {
              color: $breadcrumbs-color;
            }
          }
        }
      }
    }
  }
}