@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Button {
  padding: 13px 38px 10px 25px;
  font-family: 'bebas-bold', sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: .5px;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  border: 0;
  outline: none;
  &::after {
    content: '';
    position: absolute;
    top: 14px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
}

.Yellow {
  padding: 13px 38px 10px 25px;
  width: 153px;
  background: url('../../../assets/images/mainPage/yellow-btn.svg') no-repeat;
  color: $color_black-base;
  &::after {
    right: 23px;
    border-left: 9px solid $color_black-base;
  }
  &:disabled {
    opacity: 0.3;
  }
}

.Transparent {
  width: 168px;
  background: url('../../../assets/images/mainPage/white-btn.svg') no-repeat;
  color: $color_white-base;
  &::after {
    right: 21px;
    border-left: 9px solid $color_white-base;
  }
}

.WithoutBorder {
  background: none;
  color: $color_white-base;
  &::after {
    content: none;
  }
}

@include respond-to($desktop) {
  .Button {
    font-size: 13px;
    line-height: 16px;
    background-size: 100%;
    letter-spacing: .4px;
  }
  .Yellow {
    padding: 10px 30px 10px 20px;
    width: 122px;
    &::after {
      top: 10px;
      right: 15px;
    }
  }
}

@include respond-to($tablet) {
  .Button {
    &.DesktopOnly {
      display: none;
    }
  }
}

@include respond-to($mobile) {
  .Button {
    width: 140px;
    height: 36px;
    background-size: 100%;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .45px;
  }
  .Transparent {
    padding: 11px 29px 11px 17px;
    &::after {
      top: 12px;
      right: 17px;
    }
  }
  .Yellow {
    padding-top: 11px;
    &::after {
      top: 12px;
    }
  }
}