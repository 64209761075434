@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.List {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.Item {
  width: 426px;
  margin-bottom: 47px;
  padding: 20px 26px 26px 21px;
  font-family: 'avante-regular', sans-serif;
  background: $item-bg;
  color: $color_white-base;
  .Price {
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 18px;
    span {
      color: $color_yellow-base;
    }
  }
  .Poster {
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }
  .Title {
    margin-bottom: 7px;
    font-size: 20px;
    line-height: 22px;
  }
  .Description {
    font-size: 13px;
    line-height: 20px;
  }
}

@include respond-to($desktop) {
  .Item {
    width: 300px;
    padding: 14px 12px 15px 12px;
    margin-bottom: 33px;
    .Price {
      font-size: 11px;
      line-height: 13px;
    }
    .Price,
    .Poster {
      margin-bottom: 10px;
    }
    .Title {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 15px;
    }
    .Description {
      font-size: 9px;
      line-height: 14px;
    }
  }
}

@include respond-to($tablet) {
  .Item {
    width: 48%;
    margin-bottom: 30px;
  }
}

@include respond-to($mobile) {
  .List {
    margin-top: 20px;
  }
  .Item {
    width: 48%;
    margin-bottom: 30px;
    padding: 16px;
    .Poster {
      margin-bottom: 10px;
    }
    .Title {
      font-size: 16px;
    }
    .Description {
      font-size: 12px;
    }
  }
}

@media (min-width: 320px) and (max-width: 569px) {
  .List {
    justify-content: center;
  }
  .Item {
    width: $mobile-width;
  }
}