@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Popup {
  position: fixed;
  z-index: 500;
  background-color: $main-bg-color;
  width: 456px;
  padding: 30px;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@include respond-to($mobile) {
  .Popup {
    width: $mobile-width;
    padding: 25px 43px 23px 43px;
  }
}