@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.FranchizeSecondContent {
  padding-top: 80px;
  padding-bottom: 100px;
  background-color: $black-color;

  .Container {
    @include desktop-lg-container();
  }

  .Top {
    display: flex;
    align-items: center;
    margin-bottom: 80px;

    .Left {
      margin-right: 115px;

      img {
        width: 550px;
      }
    }

    .Right {
      h3 {
        width: 280px;
        margin-bottom: 25px;
      }

      .Descriptions {
        width: 600px;

        p {
          &:first-child {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  .Features {
    display: flex;
    justify-content: space-between;

    .Feature {
      position: relative;
      width: 325px;
      padding-left: 50px;
      font-family: 'avante-regular', sans-serif;
      line-height: 1.5;
      opacity: 0.8;

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        width: 32px;
        height: 32px;
        background-image: url('../../assets/images/franchizePage/feature-2.svg');
      }

      &:first-child {
        width: 330px;

        &::before {
          background-image: url('../../assets/images/franchizePage/feature-1.svg');
        }
      }

      &:last-child {
        width: 410px;

        &::before {
          background-image: url('../../assets/images/franchizePage/feature-3.svg');
        }
      }
    }
  }
}

@include respond-to($desktop) {
  .FranchizeSecondContent {
    .Container {
      @include desktop-container();
    }

    .Top {
      .Left {
        margin-right: 50px;

        img {
          width: 430px;
        }
      }

      .Right {
        .Descriptions {
          width: 460px;
        }
      }
    }

    .Features {
      .Feature {
        width: 32%;

        &:first-child,
        &:last-child {
          width: 32%;
        }

        &::before {
          top: 20px;
        }
      }
    }
  }
}

@include respond-to($tablet) {
  .FranchizeSecondContent {
    .Container {
      @include tablet-container();
    }

    .Top {
      flex-direction: column;

      .Left {
        margin-right: 0;

        img {
          width: 100%;
        }
      }

      .Right {
        margin-top: 40px;

        h3,
        .Descriptions {
          width: 100%;
        }
      }
    }

    .Features {
      flex-direction: column;

      .Feature {
        width: 100%;
        margin-bottom: 30px;

        &:first-child,
        &:last-child {
          width: 100%;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          top: -5px;
        }
      }
    }
  }
}

@include respond-to($mobile) {
  .FranchizeSecondContent {
    padding-top: 40px;
    padding-bottom: 60px;
    
    .Container {
      @include mobile-container();
    }

    .Top {
      flex-direction: column;
      margin-bottom: 40px;

      .Left {
        margin-right: 0;

        img {
          width: 100%;
        }
      }

      .Right {
        margin-top: 30px;

        h3,
        .Descriptions {
          width: 100%;
        }

        .Descriptions {
          &:first-child {
            margin-bottom: 30px;
          }
        }
      }
    }

    .Features {
      flex-direction: column;

      .Feature {
        width: 100%;
        margin-bottom: 30px;
        padding-left: 40px;
        font-size: 14px;

        &:first-child,
        &:last-child {
          width: 100%;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          top: 50%;
          transform: translate(0, -50%);
          width: 27px;
          height: 27px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}