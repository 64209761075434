@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Main{
  @include desktop-lg-container();

  &Content {
    position: relative;
    padding-top: 10px;
    margin-bottom: 40px;
    &Top {
      position: relative;
      text-align: center;
      .Heroes {
        width: 560px;
      }
      .Plays {
        width: 950px;
        position: absolute;
        bottom: 80px;
        left: 50%;
        transform: translate(-50%, 0);
        font-family: 'gilroy-heavy-italic', sans-serif;
        font-size: 70px;
        line-height: 1.2;
        color: $color_white-base;
        text-transform: uppercase;
        span {
          color: $color_yellow-base;
        }
      }
    }
    &Bottom {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .Wrapper {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 115px;
        padding: 20px 35px;
        background: url('../../assets/images/mainPage/wrapper.png');
        background-size: 100% 100%;
        width: 905px;
      }
    }
  }
}

.Title {
  width: 245px;
  font-family: 'gilroy-bold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: $color_white-base;
  span {
    display: block;
    color: $color_yellow-base;
  }
}

.Description {
  width: 402px;
  font-size: 14px;
  line-height: 22px;
  color: $color_white-base;
}

.ChooseRoom {
  position: absolute;
  right: 35px;
}

@include respond-to($desktop) {
  .Main {
    width: $desktop-width;
    &Content {
      padding-top: 30px;
      margin-bottom: 50px;
      &Top {
        .Plays {
          bottom: 50px;
          width: 100%;
        }
      }
      &Bottom {
        .Wrapper {
          margin-right: 0;
        }
      }
    }
  }
  .Title {
    width: 210px;
    font-size: 15px;
    line-height: 18px;
  }
  .Description {
    width: 390px;
    font-size: 12px;
    line-height: 18px;
  }
  .ChooseRoom {
    button {
      padding: 14px 38px 14px 25px;
    }
  }
}

@include respond-to($tablet) {
  .Main {
    min-width: $tablet-width;
    width: 91%;
    &Content {
      margin: 0;
      padding-top: 100px;
      padding-bottom: 120px;
      &Top {
        margin-bottom: 24px;
        .Heroes {
          display: none;
        }
        .Plays {
          position: static;
          transform: none;
          width: auto;
          font-size: 63px;
          line-height: 134px;
        }
      }
      &Bottom {
        .Wrapper {
          padding: 20px;
          margin-right: 0;
        }
        ul {
          display: none;
        }
      }
    }
  }
  .Title,
  .Description {
    width: auto;
  }
  .Title {
    margin-right: 10px;
    span {
      display: inline;
    }
  }
  .ChooseRoom {
    left: 50%;
    transform: translate(-55%, 0);
    bottom: -70px;
  }
}

@include respond-to($mobile) {
  .Main {
    min-width: $mobile-width;
    width: 94%;
    &Content {
      margin-bottom: 0;
      padding-top: 75px;
      padding-bottom: 110px;
      &Top {
        margin-bottom: 23px;
        .Heroes {
          display: none;
        }
        .Plays {
          position: static;
          transform: none;
          width: auto;
          font-size: 25px;
          line-height: 69px;
        }
      }
      &Bottom {
        .Wrapper {
          flex-direction: column;
          width: 100%;
          margin-right: 0;
          padding: 0;
          background: none;
        }
        ul {
          display: none;
        }
      }
    }
  }

  .Title,
  .Description {
    width: auto;
  }

  .Title {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 15px;
    span {
      display: inline;
    }
  }

  .Description {
    text-align: center;
    font-size: 10px;
    line-height: 15px;
  }

  .ChooseRoom {
    bottom: -70px;
    left: 0;
  }
}