@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Title {
  font-family: 'bebas-bold', sans-serif;
  font-size: 40px;
  line-height: 1.3;
  color: $color_white-base;
  
  span {
    color: $color_yellow-base;
  }
}

@include respond-to($desktop) {
  .Title {
    font-size: 33px;
  }
}

@include respond-to($tablet) {
  .Title {
    font-size: 30px;
  }
}

@include respond-to($mobile) {
  .Title {
    font-size: 28px;
  }
}