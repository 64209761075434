@import '../../assets/scss/mixins';

.Download {
  text-align: center;
  h2 {
    margin-bottom: 10px;
    font-size: 30px;
  }
  p {
    width: 380px;
    margin: 0 auto;
  }
}

@include respond-to($desktop) {
  .Download {
    h2 {
      margin-bottom: 8px;
      font-size: 24px;
      line-height: 41px;
    }
    p {
      width: 310px;
    }
  }
}

@include respond-to($mobile) {
  .Download {
    h2 {
      font-size: 20px;
      line-height: 30px;
    }
    p {
      width: 100%;
    }
  }
}