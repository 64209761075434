@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Container {
  @include desktop-lg-container();
  padding-top: 132px;
  padding-bottom: 30px;
  color: $color_white-base;
  .ShowMore {
    text-align: center;
    button {
      padding: 13px 45px 10px 35px;
    }
  }
}

@include respond-to($desktop) {
  .Container {
    width: $desktop-width;
  }
}

@include respond-to($tablet) {
  .Container {
    width: 100%;
    padding: 0 4.5%;
    padding-top: 90px;
    padding-bottom: 40px;
  }
}

@include respond-to($mobile) {
  .Container {
    width: 100%;
    padding: 0 3%;
    padding-top: 66px;
    padding-bottom: 30px;
    .ShowMore {
      button {
        padding: 11px 30px 10px 25px;
      }
    }
  }
}