@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Wrapper {
  background: $main-bg-color;
  .Container {
    @include desktop-lg-container();
    padding-top: 120px;
  }
}

@include respond-to($desktop) {
  .Wrapper {
    .Container {
      width: $desktop-width;
      padding-top: 105px;
    }
  }
}

@include respond-to($tablet) {
  .Wrapper {
    .Container {
      min-width: $tablet-width;
      width: 91%;
      padding-top: 100px;
    }
  }
}

@include respond-to($mobile) {
  .Wrapper {
    .Container {
      min-width: $mobile-width;
      width: 94%;
      padding-top: 78px;
      padding-bottom: 35px;
      button {
        display: none;
      }
    }
  }
}