@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Item {
  position: relative;
  display: flex;
  width: 255px;
  margin-bottom: 21px;
  color: $color_white-base;
  .Left {
    img {
      display: block;
    }
  }
  .Right {
    width: 100%;
    padding: 17px;
    font-family: 'avante-regular', sans-serif;
    background-color: $color_black-base;
    .Title {
      margin-bottom: 10px;
    }
    .Players {
      font-size: 12px;
      line-height: 14px;
      color: $board_games-color;
      span {
        color: $color_white-base;
      } 
    }
  }
  &::after {
    content: '';
    position: absolute;
    right: -9px;
    bottom: -3px;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid $board_games-bg-color;
    transform: rotate(136deg);
  }
}

@include respond-to($desktop) {
  .Item {
    width: 204px;
    .Left {
      img {
        width: 62px;
      }
    }
    .Right {
      padding: 12px;
      .Title {
        font-size: 12px;
        line-height: 14px;
      }
      .Players {
        font-size: 10px;
        line-height: 11px;
      }
    }
    
  }
}