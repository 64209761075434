@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins';

.Item {
  padding: 26px 15px;
  font-family: 'avante-regular', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: $color_white-base;
  opacity: 0.5;
  cursor: pointer;
  white-space: nowrap;
  &.Active {
    border-bottom: 2px solid $color_yellow-base;
    opacity: 1;
    color: $color_yellow-base;
  }
}

@include respond-to($desktop) {
  .Item {
    padding: 18px;
    font-size: 12px;
    line-height: 14px;
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .Item {
    margin: 0 10px;
    padding: 10px 0;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    border-top: 1px solid #393939;
    color: #B3B3B3;
    opacity: 1;
    &.Active {
      border: none;
      border-top: 1px solid #393939;
      opacity: 1;
      color: $color_yellow-base;
    }
    &.First {
      margin: 0;
      padding: 10px 12px;
    }
  }
}