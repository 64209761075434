@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.List {
  display: flex;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: $gray_color;
    opacity: 0.2;
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .Categories {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .ActiveCategory {
      width: 210px;
      text-align: left;
      padding: 8px 15px;
      font-size: 12px;
      line-height: 14px;
      color: $color_yellow-base;
      background: $color_black-base;
      &::after {
        content: '';
        position: absolute;
        top: 12px;
        right: 12px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: $color_yellow-base transparent transparent transparent;
      }
    }
  
    .List {
      content: '';
      position: absolute;
      top: 30px;
      width: 210px;
      display: block;
      background: $color_black-base;
      border-bottom: 0;
      &::after {
        content: none;
      }
    }
  }
}