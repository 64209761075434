@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Wrapper {
  display: flex;
  .Left {
    width: 687px;
    .Top {
      margin-bottom: 20px;
      img {
        width: 674px;
        height: 394px;
        cursor: pointer;
      }
    }
    .Bottom {
      img {
        width: 215px;
        height: 121px;
        cursor: pointer;
      }
    }
  }
  .Right {
    width: 560px;
    margin-left: 34px;
    font-family: 'avante-regular', sans-serif;
    .Title {
      margin-bottom: 50px;
      font-size: 60px;
      line-height: 70px;
      color: $color_white-base;
    }
    .Category,
    .Price,
    .MaxPlayers,
    .Projector {
      margin-bottom: 11px;
      font-size: 12px;
      line-height: 14px;
      color: $room-gray-color;
      span {
        color: $color_white-base;
      }
    }
    .Description {
      margin-top: 10px;
      font-size: 13px;
      line-height: 20px;
      color: $color_white-base;
      opacity: 0.5;
    }
  }
}

@include respond-to($desktop) {
  .Wrapper {
    .Left {
      width: 490px;
      .Top {
        margin-bottom: 14px;
        img {
          width: 482px;
          height: 281px;
        }
      }
      .Bottom {
        img {
          width: 153px;
          height: 86px;
        }
      }
    }
    .Right {
      margin-left: 19px;
    }
  }
}

@include respond-to($tablet) {
  .Wrapper {
    flex-direction: column;
    .Left {
      width: 100%;
      .Top {
        margin-bottom: 20px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .Bottom {
        display: none;
      }
    }
    .Right {
      width: 100%;
      margin-left: 0;
      .Title {
        display: none;
      }
    }
  }
}

@include respond-to($mobile) {
  .Wrapper {
    flex-direction: column;
    .Left {
      width: 100%;
      .Top {
        img {
          width: 100%;
          height: auto;
        }
      }
      .Bottom {
        display: none;
      }
    }
    .Right {
      width: 100%;
      margin-left: 0;
      .Title {
        display: none;
      }
      .Description {
        margin-top: 21px;
      }
    }
  }
}
