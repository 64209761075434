@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Title {
  font-family: 'bebas-bold', sans-serif;
  font-size: 60px;
  line-height: 72px;
  color: $color_white-base;
}

@include respond-to($desktop) {
  .Title {
    font-size: 50px;
    line-height: 60px;
  }
}

@include respond-to($mobile) {
  .Title {
    font-size: 30px;
    line-height: 36px;
  }
}