@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.FranchizeMainContent {
  padding-top: 170px;
  padding-bottom: 60px;
  background-image: url('../../assets/images/franchizePage/main.png');
  background-size: cover;
  background-repeat: no-repeat;

  .Container {
    @include desktop-lg-container();
    display: flex;
    justify-content: space-between;
  }

  .Left {
    position: relative;
    margin-top: 150px;
    z-index: 1;

    .Title {
      font-family: 'avante-regular', sans-serif;
      font-size: 40px;
      text-transform: uppercase;

      span {
        display: block;
        font-family: 'bebas-bold', sans-serif;
        font-size: 170px;
        color: $color_yellow-base;
      }
    }

    .Description {
      width: 305px;
      margin-bottom: 40px;
    }
  }

  .Right {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 90px;
      left: -390px;
      width: 415px;
      height: 271px;
      background-image: url('../../assets/images/franchizePage/effect.png');
      background-size: contain;
      background-repeat: no-repeat;
    }

    img {
      width: 790px;
    }
  }
}

@include respond-to($desktop) {
  .FranchizeMainContent {
    .Container {
      @include desktop-container();
    }

    .Right {
      &::before {
        content: none;
      }

      img {
        width: 650px;
      }
    }
  }
}

@include respond-to($tablet) {
  .FranchizeMainContent {
    .Container {
      @include tablet-container();
    }
  }
}

@include respond-to($mobile) {
  .FranchizeMainContent {
    padding-bottom: 40px;
    .Container {
      @include mobile-container();
    }

    .Left {
      margin-top: 80px;

      .Title {
        span {
          font-size: 62px;
        }
      }

      .Description {
        margin-bottom: 30px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .FranchizeMainContent {
    padding-top: 0;
    background: $franchize-bg;

    .Container {
      justify-content: center;
    }

    .Left {
      text-align: center;

      .Title {
        font-family: 'bebas-bold', sans-serif;
        font-size: 35px;

        span {
          font-family: 'gilroy-heavy-italic', sans-serif;
        }
      }

      .Description {
        width: 100%;
      }
    }

    .Right {
      &::before {
        content: none;
      }

      img {
        display: none;
      }
    }
  }
}