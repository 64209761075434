@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Locations {
  height: 670px;
  padding-top: 55px;
  background: url('../../assets/images/mainPage/locations-bg.png');
  background-size: 100% 100%;
  .Container {
    @include desktop-lg-container();

    .Wrapper {
      display: flex;
      justify-content: space-between;
      padding-top: 70px;
      li {
        width: 400px;
      }
    }
  }
}

.List {
  display: flex;
  justify-content: space-between;
  padding-top: 70px;
}

@include respond-to($desktop) {
  .Locations {
    height: 560px;
    padding-top: 43px;
    .Container {
      width: $desktop-width;
      .Wrapper {
        li {
          width: 280px;
        }
      }
    }
  }
  .List {
    padding: 0;
    padding-top: 68px;
  }
}

@include respond-to($tablet) {
  .Locations {
    padding-top: 30px;
    padding-bottom: 80px;
    height: auto;
    .Container {
      min-width: $tablet-width;
      width: 91%;
      .Wrapper {
        padding-top: 0;
        li {
          width: 32%;
        }
      }
    }
  }
  .List {
    display: block;
  }
}

@include respond-to($mobile) {
  .Locations {
    height: auto;
    padding-top: 20px;
    padding-bottom: 10px;
    .Container {
      min-width: 300px;
      width: 94%;
      .Wrapper {
        flex-direction: column;
        padding-top: 20px;
        li {
          width: 100%;
        }
      }
    }
  }
  .List {
    justify-content: space-around;
    align-items: center;
    padding: 0;
    padding-top: 25px;
  }
}

@media (min-width: 320px) and (max-width: 680px) {
  .List {
    flex-direction: column;
  }
}