@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixins';

.NavigationItem {
  margin: 0 28px;
  a {
    font-family: 'avante-regular', sans-serif;
    font-size: 15px;
    line-height: 18px;
    text-decoration: none;
    color: $color_white-base;
    cursor: pointer;
    letter-spacing: 1px;
  }
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: $color_yellow-base;
}

@include respond-to($desktop) {
  .NavigationItem {
    margin: 0 22px;
    a {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

@include respond-to($tablet) {
  .NavigationItem {
    margin-bottom: 55px;
  }
}

@include respond-to($mobile) {
  .NavigationItem {
    margin-bottom: 55px;
  }
}