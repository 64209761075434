@import '../../../../../../assets/scss/mixins';

.DrawerToggle {
  width: 40px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: none;
}

@include respond-to($tablet) {
  .DrawerToggle {
    display: block;
    text-align: right;
  }
}

@include respond-to($mobile) {
  .DrawerToggle {
    display: block;
    text-align: right;
  }
}