@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Content {
  min-height: calc(100vh - 56px);
}

.ModalContent {
  position: relative;
  font-family: 'avante-regular', sans-serif;
  .ModalTitle {
    color: $color_white-base;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
  }
  form {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    margin-bottom: 23px;
  }
  .ModalBtns {
    display: flex;
    justify-content: flex-end;
  }
  .ModalClose {
    position: absolute;
    top: 0;
    right: 0;
    img {
      cursor: pointer;
    }
  }
}

.Popup {
  text-align: center;
  font-family: 'avante-regular', sans-serif;
  color: $color_white-base;
  .Title {
    margin-bottom: 28px;
    font-size: 20px;
    line-height: 23px;
  }
  .Description {
    margin-bottom: 30px;
    font-size: 13px;
    line-height: 15px;
    color: #A6A6A6;
  }
}

@include respond-to($tablet) {
  .Element {
    .Wrapper {
      padding: 0 6px;
    }
  }
  .ModalContent {
    .ModalTitle {
      font-size: 16px;
      line-height: 19px;
    }
    form {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .ModalBtns {
      justify-content: space-between;
    }
    .ModalClose {
      img {
        width: 17px;
      }
    }
  }
}

@include respond-to($mobile) {
  .Element {
    .Wrapper {
      padding: 0 6px;
    }
  }

  .ModalContent {
    .ModalTitle {
      font-size: 16px;
      line-height: 19px;
    }
    form {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .ModalBtns {
      justify-content: space-between;
    }
    .ModalClose {
      img {
        width: 17px;
      }
    }
  }

  .Popup {
    .Title {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 19px;
    }
    .Description {
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 14px;
    }
  }
}