@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixins';

.SideDrawer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 103;
  background-color: $mobile-bg-color;
  padding: 20px 11px 30px 11px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

@include respond-to($tablet) {
  .SideDrawer {
    height: 200%;
    .CloseBurger {
      margin-bottom: 30px;
      text-align: right;
    }
    .Social {
      margin-top: 65px;
    }
    footer {
      background-color: transparent;
      div {
        width: auto;
      }
    }
  }
}

@include respond-to($mobile) {
  .SideDrawer {
    height: 200%;
    .CloseBurger {
      margin-bottom: 30px;
      text-align: right;
    }
    .Social {
      margin-top: 65px;
    }
    footer {
      background-color: transparent;
      div {
        width: auto;
      }
    }
  }
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(500%);
}