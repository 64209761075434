@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

h2 {
  font-family: 'vanchrome', sans-serif;
  font-size: 40px;
  line-height: 52px;
  color: $color_white-base;
  span {
    color: $color_yellow-base;
  }
}

@include respond-to($desktop) {
  h2 {
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 41px;
  }
}

@include respond-to($mobile) {
  h2 {
    margin-bottom: 5px;
    font-size: 35px;
    line-height: 42px;
  }
}