@import '../../assets/scss/mixins';

.FranchizeFigure {
  display: flex;
  align-items: center;
  width: 810px;
  height: 110px;
  margin: 0 auto;
  background-image: url('../../assets/images/franchizePage/figure.png');
  background-size: cover;
  background-repeat: no-repeat;

  p {
    margin: 0;
  }

  .Description {
    width: 500px;
    margin-right: 50px;
    margin-left: 40px;
    text-align: left;
    font-family: 'avante-regular', sans-serif;
    line-height: 1.5;
  }
}

@include respond-to($tablet) {
  .FranchizeFigure {
    width: auto;
    max-width: 810px;
    background-size: 100% 100%;
  }
}

@media (min-width: 768px) and (max-width: 860px) {
  .FranchizeFigure {
    justify-content: space-between;
    padding: 0 40px;
    .Description {
      margin: 0;
      margin-right: 20px;
    }
  }
}

@include respond-to($mobile) {
  .FranchizeFigure {
    display: none;
  }
}