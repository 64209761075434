@import '../../../assets/scss/mixins';

.Links {
  margin-top: 50px;
  a {
    cursor: pointer;
  }
  .AppStore {
    margin-right: 30px;
    img {
      width: 130px;
      height: 43px;
    }
  }

  .GooglePlay {
    img {
      width: 140px;
      height: auto;
    }
  }
}

@include respond-to($desktop) {
  .Links {
    margin-top: 40px;
  }
}

@include respond-to($tablet) {
  .Links {
    text-align: center;
  }
}

@include respond-to($mobile) {
  .Links {
    margin-top: 38px;
    text-align: center;
  }
}