@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Advantages {
  padding-top: 95px;
  padding-bottom: 125px;
  background-color: $franchize-bg;

  .Container {
    @include desktop-lg-container();
  }

  h3 {
    margin-bottom: 65px;
    text-align: center;
  }

  .AdvantagesList {
    display: flex;
    justify-content: space-between;

    .Advantage {
      width: 430px;
      padding: 30px 40px 65px 40px;
      background: url('../../assets/images/franchizePage/advantage.png') no-repeat;
      background-size: 100% 100%;

      .Subtitle {
        font-family: 'bebas-bold', sans-serif;
        font-size: 40px;
        line-height: 1.3;
        color: $color_yellow-base;
      }

      .Title {
        margin-top: 15px;
        margin-bottom: 25px;
        font-family: 'avante-regular', sans-serif;
        font-size: 20px;
        line-height: 1.5;
      }

      .Description {
        font-family: 'avante-regular', sans-serif;
        line-height: 1.5;
        opacity: 0.6;
      }
    }
  }
}

@include respond-to($desktop) {
  .Advantages {
    .Container {
      @include desktop-container();
    }

    .AdvantagesList {
      .Advantage {
        width: 32%;
        padding: 20px;
      }
    }
  }
}

@include respond-to($tablet) {
  .Advantages {
    .Container {
      @include tablet-container();
    }

    .AdvantagesList {
      .Advantage {
        width: 32%;
        padding: 5px;
        background: none;

        .Subtitle {
          font-size: 30px;
        }

        .Title {
          font-size: 18px;
        }
      }
    }
  }
}

@include respond-to($mobile) {
  .Advantages {
    padding: 40px 0;

    .Container {
      @include mobile-container();
    }

    h3 {
      margin-bottom: 35px;
      text-align: left;
    }

    .AdvantagesList {
      flex-direction: column;

      .Advantage {
        width: 100%;
        margin-bottom: 35px;
        padding: 0;
        background: none;

        &:last-child {
          margin-bottom: 0;
        }

        .Subtitle {
          font-size: 28px;
        }

        .Title {
          margin-top: 10px;
          margin-bottom: 15px;
          font-size: 16px;
        }

        .Description {
          font-size: 14px;
        }
      }
    }
  }
}