@import '../../../../assets/scss/_mixins.scss';

.NavigationItems {
  display: flex;
}

@include respond-to($tablet) {
  .NavigationItems {
    display: block;
    text-align: center;
  }
}

@include respond-to($mobile) {
  .NavigationItems {
    display: block;
    text-align: center;
  }
}