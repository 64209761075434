@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

// .react-datepicker-popper {
//   .react-datepicker {
//     background: $input-bg-color;
//     border-radius: 0;
//     border: 0;
//     &__triangle {
//       display: none;
//     }
//     &__navigation--previous {
//       border-right-color: $color_white-base;
//     }
//     &__navigation--next {
//       border-left-color: $color_white-base;
//     }
//     &__navigation--previous--disabled {
//       border-right-color: $breadcrumbs-color;
//     }
//     &__navigation--previous,
//     &__navigation--next,
//     &__navigation--previous--disabled {
//       &:focus {
//         outline: none;
//       }
//     }
//     &__header {
//       background: $input-bg-color;
//       border: 0;
//     }
//     &__month {
//       margin: 0 .4rem;
//     }
//     &__current-month,
//     &__day-name {
//       color: $color_white-base;
//     }
//     &__day {
//       color: $color_white-base;
//       &:hover {
//         background: transparent;
//         color: $color_yellow-base;
//       }
//       &--today {
//         font-weight: normal;
//         background: transparent;
//         &:hover {
//           background: transparent;
//         }
//       }
//       &--selected {
//         background: transparent;
//         color: $color_yellow-base;
//       }
//       &--disabled {
//         color: $breadcrumbs-color;
//         &:hover {
//           color: $breadcrumbs-color;
//         }
//       }
//     }
//   }
// }

// @include respond-to($tablet) {
//   .react-datepicker-wrapper {
//     width: 100%;
//   }
// }

// @include respond-to($mobile) {
//   .react-datepicker-wrapper {
//     width: 100%;
//   }
// }

.ant-calendar {
  width: 235px;
  border-radius: 0;
  border-color: $board_games-color;
  background: $input-bg-color;
  &-header {
    border-bottom-color: $board_games-color;
  }
  &-footer {
    border-top-color: $board_games-color;
  }
  &-picker {
    &-input {
      width: 208px !important;
      height: 40px !important;
      font-family: 'avante-regular', sans-serif;
      border-color: transparent !important;
      box-shadow: transparent !important;
      &:focus {
        box-shadow: none !important;
      }
    }
    .anticon {
      display: none;
    }
  
  }
  &-input {
    text-align: center;
    background: transparent;
    color: $color_white-base !important;
    &-wrap {
      display: none;
    }
  }
  &-column-header {
    span {
      color: $color_white-base;
    }
  }

  &-cell {
    div {
      color: $color_white-base;
      &:hover {
        color: $color_yellow-base;
        background: transparent !important;
        border: 1px solid $color_yellow-base;
      }
    }
    &.ant-calendar-disabled-cell {
      div {
        color: $board_games-color;
        background: none;
        &:hover {
          border: 1px solid transparent;
        }
      }
    }
  }
  &-today {
    div {
      font-weight: normal !important;
      color: $color_white-base !important;
      border-color: transparent !important;
      &:hover {
        color: $color_yellow-base !important;
        background: transparent !important;
        border: 1px solid $color_yellow-base !important;
      }
    }
  }
  &-selected-day {
    div {
      color: $color_yellow-base;
      background: transparent !important;
      border: 1px solid $color_yellow-base;
    }
  }

  &-prev-month-btn {
    left: 12px !important;
  }

  &-next-month-btn {
    right: 12px !important;
  }

  &-prev-year-btn,
  &-next-year-btn,
  &-today-btn {
    display: none !important;
  }

  &-footer-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-last-month-cell,
  &-next-month-btn-day {
    div {
      color: $board_games-color !important;
    }
  }

  &-day-select,
  &-month-select,
  &-year-select,
  &-today-btn,
  &-time-picker-btn {
    color: $color_white-base !important;
  }

  &-ok-btn {
    background-color: transparent !important;
    border: 1px solid $color_yellow-base !important;
    color: $color_white-base !important;
  }
}

.ant-calendar-header .ant-calendar-prev-century-btn:hover:after, .ant-calendar-header .ant-calendar-prev-century-btn:hover:before, .ant-calendar-header .ant-calendar-prev-decade-btn:hover:after, .ant-calendar-header .ant-calendar-prev-decade-btn:hover:before, .ant-calendar-header .ant-calendar-prev-year-btn:hover:after, .ant-calendar-header .ant-calendar-prev-year-btn:hover:before,
.ant-calendar-header .ant-calendar-next-century-btn:hover:after, .ant-calendar-header .ant-calendar-next-century-btn:hover:before, .ant-calendar-header .ant-calendar-next-decade-btn:hover:after, .ant-calendar-header .ant-calendar-next-decade-btn:hover:before, .ant-calendar-header .ant-calendar-next-year-btn:hover:after, .ant-calendar-header .ant-calendar-next-year-btn:hover:before,
.ant-calendar-header .ant-calendar-prev-month-btn:hover:after, .ant-calendar-header .ant-calendar-prev-month-btn:hover:before,
.ant-calendar-header .ant-calendar-next-month-btn:hover:after, .ant-calendar-header .ant-calendar-next-month-btn:hover:before {
  border-color: $color_white-base !important;
}

.ant-calendar-cell.ant-calendar-disabled-cell-last-of-row.ant-calendar-selected-day.ant-calendar-disabled-cell {
  div {
    background: none !important;
  }
}

.ant-calendar-time-picker-select {
  background: $input-bg-color !important;
  border-right-color: $board_games-color;
  li {
    color: $color_white-base;
    &:hover {
      color: $color_yellow-base !important;
      background: transparent !important;
    }
    &.ant-calendar-time-picker-select-option-disabled {
      &:hover {
        color: $board_games-color !important;    
      }
    }
  }
  &-option-disabled {
    color: $board_games-color !important;
  }
  &-option-selected {
    color: $color_yellow-base !important;
    background: transparent !important;
  }
}

.ant-calendar-time-picker-select li:focus {
  color: $color_white-base;
  font-weight: normal;
}
.ant-calendar-time-picker-select li:last-child:after {
  height: auto;
}

.ant-calendar-cell.ant-calendar-today.ant-calendar-selected-day {
  .ant-calendar-date {
    &:hover {
      color: $color_yellow-base !important;
      background: transparent !important;
      border: 1px solid $color_yellow-base !important;
    }
  }
}

.ant-calendar-cell.ant-calendar-today.ant-calendar-selected-date.ant-calendar-selected-day {
  .ant-calendar-date {
    color: $color_yellow-base !important;
    border: 1px solid $color_yellow-base !important;
    
  }
}

.ant-calendar-cell.ant-calendar-selected-day.ant-calendar-disabled-cell {
  .ant-calendar-date {
    background: hsl(0, 0%, 96%) !important;
    &::before {
      background: transparent;
    }
  }
}


@include respond-to($tablet) {
  .ant-calendar {
    &-picker {
      &-input {
        width: 100% !important;
      }
    }
    .ant-calendar-time-picker-select {
      overflow-y: auto;
    }
  }
}

@include respond-to($mobile) {
  .ant-calendar {
    &-picker {
      &-input {
        width: 100% !important;
      }
    }
    .ant-calendar-time-picker-select {
      overflow-y: auto;
    }
  }
}