@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Wrapper {
  width: 65vw;
  margin: 0 auto;
}

.News {
  padding-top: 47px;
  padding-bottom: 72px;
  .Container {
    @include desktop-lg-container();
  }
  .ShowMore {
    text-align: center;
    button {
      width: 161px;
    }
  }
}

@include respond-to($desktop) {
  .News {
    .Container {
      width: $desktop-width;
    }
    padding-top: 43px;
    padding-bottom: 54px;
    .List {
      margin-top: 26px;
    }
    .ShowMore {
      text-align: center;
      button {
        padding: 13px 36px 13px 21px;
      }
    }
  }
}

@include respond-to($tablet) {
  .News {
    .Container {
      position: relative;
      min-width: $tablet-width;
      width: 91%;
    }
    .ShowMore {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .Wrapper {
    width: 75vw;
    margin: 0 auto;
  }
}

@include respond-to($mobile) {
  .News {
    padding-top: 20px;
    padding-bottom: 60px;
    .Container {
      position: relative;
      min-width: $mobile-width;
      width: 94%;
    }
    .ShowMore {
      position: absolute;
      top: 0;
      right: 0;
      button {
        width: 130px;
        padding: 9px 29px 11px 17px;
        &::after {
          top: 10px;
        }
      }
    }
  }
}
