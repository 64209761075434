@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Feature {
  display: flex;
  width: 265px;
  &Left {
    margin-right: 15px;
    img {
      width: 57px;
      height: 56px;
    }
  }
  &Right {
    text-align: left;
  }
  &Title {
    font-family: 'bebas-bold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: $color_yellow-base;
  }
  &Text {
    font-family: 'avante-regular', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: $color_white-base;
  }
  &.Even {
    width: 270px;
    margin: 0 30px;
  }
}

@include respond-to($desktop) {
  .Feature {
    width: 195px;
    &Left {
      margin-right: 11px;
      img {
        width: 43px;
        height: 42px;
      }
    }
    &Title {
      font-size: 15px;
      line-height: 18px;
    }
    &Text {
      font-size: 11px;
      line-height: 13px;
    }
    &.Even {
      width: 190px;
      margin: 0 26px;
    }
  }
}
