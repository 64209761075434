@import '../../../assets/scss/mixins';

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

@include respond-to($tablet) {
  .Backdrop {
    height: 200%;
  }
}

@include respond-to($mobile) {
  .Backdrop {
    height: 200%;
  }
}