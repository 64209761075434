@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Breadcrumbs {
  margin-bottom: 30px;
  font-family: 'avante-regular', sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: $breadcrumbs-color;
  li {
    display: inline-block;
    .Pointer {
      cursor: pointer;
      &:hover {
        color: $color_white-base;
      }
    }
    &.Back {
      display: none;
    }
    &.Room {
      color: $color_white-base;
    }
  }
}

@include respond-to($desktop) {
  .Breadcrumbs {
    margin-bottom: 27px;
  }
}

@include respond-to($tablet) {
  .Breadcrumbs {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
    li {
      &.MobileOnly {
        display: none;
      }
      &.Back {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
      }
      &.Room {
        font-size: 20px;
        line-height: 23px;
      }
    }
  }
}

@include respond-to($mobile) {
  .Breadcrumbs {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
    li {
      &.MobileOnly {
        display: none;
      }
      &.Back {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
      }
      &.Room {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}