@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.ant-select {
  position: relative;
  width: 208px !important;
  font-family: 'avante-regular', sans-serif;
  font-size: 13px;
  line-height: 15px;
  &-disabled {
    .ant-select-selection {
      background: $input-bg-color;
    }
  }
  &-selection {
    background: $input-bg-color;
    border: none;
    border-radius: 0;
    &:active,
    &:focus {
      box-shadow: none;
    }
    &--single {
      height: auto;
      padding: 5px 0;
    }
    &__placeholder {
      color: #A6A6A6;
    }
    &-selected-value {
      color: $color_white-base;
      .CountPlayers {
        display: none;
      }
    }
  }
  &-arrow {
    position: absolute;
    top: 25px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: $color_yellow-base transparent transparent transparent;
    svg {
      display: none;
    }
  }
  &-search__field {
    padding: 12px 0 !important;
  }
  
}

.ant-select-dropdown {
  border-radius: 0;
  &-menu {
    max-height: 158px;
    background: $input-bg-color;
    &-item {
      position: relative;
      padding: 10px 12px;
      text-align: left;
      font-family: 'avante-regular', sans-serif;
      font-size: 12px;
      line-height: 14px;
      font-weight: normal;
      color: $color_white-base;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 10px;
        width: 90%;
        height: 1px;
        background: $gray_color;
        opacity: 0.05;
      }
      &:first-child,
      &:last-child {
        border-radius: 0;
      }
      &:last-child {
        &::after {
          content: none;
        }
      }
      &-selected {
        background: transparent;
      }
      &:hover:not(.ant-select-dropdown-menu-item-disabled),
      &-active:not(.ant-select-dropdown-menu-item-disabled) {
        background: transparent;
        opacity: 1;
      }
    }
  }
  .Wrapper {
    display: flex;
    justify-content: space-between;
    .CountPlayers {
      position: relative;
      padding-right: 20px;
      &::after {
        content: '';
        position: absolute;
        top: 2px;
        right: 0;
        width: 16px;
        height: 10px;
        background-image: url('../../../assets/images/locationPage/count-players.svg');
      }
    }
  }
}

.ant-select-focused .ant-select-selection, .ant-select-selection:active, .ant-select-selection:focus {
  box-shadow: none;
}


@include respond-to($tablet) {
  .ant-select {
    width: 100% !important;
    &-dropdown {
      &-menu {
        &-item {
          &::after {
            width: 97%;
          }
        }
      }
    }
  }
}

@include respond-to($mobile) {
  .ant-select {
    width: 100% !important;
    &-dropdown {
      &-menu {
        &-item {
          &::after {
            width: 93%;
          }
        }
      }
    }
  }
}