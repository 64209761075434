@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

footer {
  padding: 25px 40px;
  background-color: $footer-bg-color;
  .Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include desktop-lg-container();
  }
  .Copyright {
    font-family: 'avante-regular', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: $color_white-base;
    opacity: 0.5;
  }
}

@include respond-to($desktop) {
  footer {
    width: 100%;
    padding: 25px 35px;
    .Container {
      width: $desktop-width;
    }
    .Copyright {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

@include respond-to($tablet) {
  footer {
    width: 100%;
    padding: 20px 0;
    .Container {
      min-width: $tablet-width;
      width: 91%;
      div,
      ul {
        display: none;
      }
    }
    .Copyright {
      margin: 0 auto;
    }
  }
}

@include respond-to($mobile) {
  footer {
    width: 100%;
    padding: 20px 0;
    .Container {
      min-width: $mobile-width;
      width: 94%;
      div,
      ul {
        display: none;
      }
    }
    .Copyright {
      margin: 0 auto;
    }
  }
}