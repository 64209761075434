@import '../../../assets/scss/mixins';

.SocialBtn {
  display: block;
  width: 24px;
  height: 24px;
  font-size: 0;
  margin-left: 15px;
  background-repeat: no-repeat;
  &.Telegram {
    background-image: url('../../../assets/images/mainPage/telegram.svg');
  }
  &.Instagram {
    background-image: url('../../../assets/images/mainPage/instagram.svg');
  }
  &.Youtube {
    background-image: url('../../../assets/images/mainPage/youtube.svg');
  }
}

@include respond-to($desktop) {
  .SocialBtn {
    width: 22px;
    height: 22px;
    background-size: 99%;
  }
}

@include respond-to($tablet) {
  .SocialBtn {
    margin: 0;
    &.Instagram {
      margin: 0 25px;
    }
  }
}

@include respond-to($mobile) {
  .SocialBtn {
    width: 29px;
    height: 30px;
    margin: 0;
    background-size: 100%;
    &.Instagram {
      margin: 0 25px;
    }
  }
}