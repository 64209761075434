@import '../../assets/scss/mixins';

header {
  .ant-select {
    width: 110px !important;
    margin-right: 20px;
  }
}

@include respond-to($tablet) {
  header {
    position: relative;
    .ant-select {
      position: absolute;
      right: 100px;
      padding-right: 0;
    }
  }
}

@include respond-to($mobile) {
  header {
    position: relative;
    .ant-select {
      position: absolute;
      right: 60px;
      padding-right: 0;
    }
  }
}