$main-bg-color: #111111;
$color_black-base: #1C1C1C;
$color_white-base: #FFFFFF;
$color_yellow-base: #FFE400;
$color_gray-base: #BCBCBC;
$black-color: #000000;

$item-bg: #171717;
$franchize-bg: #1d1d1d;

$gray_color: #D8D8D8;
$board_games-bg-color: #161616;
$board_games-color: #6F6F6F;

$footer-bg-color: #0D0D0D;

$mobile-bg-color: #080808;
$dropdown-active-bg: #1E1E1E;
$dropdown-border-color: #979797;

$breadcrumbs-color: #7F7F7F;
$room-gray-color: $board_games-color;

$input-bg-color: #1A1A1A;

$desktop-lg-width: 1360px;
$desktop-width: 954px;
$tablet-width: 700px;
$mobile-width: 300px;