@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.About {
  background: $main-bg-color;
}

.Wrapper {
  background-image: url('../../assets/images/aboutAppPage/bonus-system-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.InnerContainer {
  width: 980px;
  margin: 0 auto;
  padding-bottom: 90px;
  img {
    height: auto;
  }
}

.Container {
  @include desktop-lg-container();
  padding-top: 131px;
  .InnerContainer {
    padding-bottom: 0;
    img {
      height: auto;
    }
  }
}

.FavouriteGuest {
  display: flex;
  align-items: center;
  .Left {
    width: 376px;
  }
  .Right {
    position: relative;
    left: 190px;
    width: 656px;
  }
}

.AccumulatePoints {
  position: relative;
  display: flex;
  align-items: center;
  .Left {
    position: relative;
    left: -250px;
    width: 864px;
  }
  .Right {
    content: '';
    position: absolute;
    right: 0;
    width: 400px;
    .List {
      .Item {
        margin-top: 20px;
      }
    }
  }
}

.Balance {
  display: flex;
  align-items: center;
  position: relative;
  .Left {
    content: '';
    position: absolute;
    width: 380px;
    z-index: 1;
  }
  .Right {
    position: relative;
    left: 420px;
    width: 790px;
  }
}

.AccumulatedPoints {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 190px;
  padding-bottom: 115px;
  .Left {
    position: relative;
    right: 230px;
    width: 793px;
  }
  .Right {
    position: absolute;
    right: 0;
    width: 402px;
  }
}

@include respond-to($desktop) {
  .Container {
    width: $desktop-width;
    padding-top: 113px;
    .InnerContainer {
      padding-bottom: 0;
    }
  }
  .InnerContainer {
    width: 686px;
    padding-bottom: 85px;
  }

  .FavouriteGuest {
    position: relative;
    height: 535px;
    .Left {
      position: absolute;
      width: 300px;
    }
    .Right {
      position: absolute;
      left: 360px;
      width: 459px;
    }
  }

  .AccumulatePoints {
    .Left {
      left: -190px;
      width: 605px;
    }
    .Right {
      width: 321px;
    }
  }

  .Balance {
    .Left {
      width: 308px;
    }
    .Right {
      left: 295px;
      width: 556px;
    }
  }

  .AccumulatedPoints {
    .Left {
      right: 180px;
      width: 600px;
    }
    .Right {
      width: 325px;
    }
  }
}

@include respond-to($tablet) {
  .About {
    img {
      width: 75%;
    }
  }
  .Container {
    min-width: $tablet-width;
    width: 91%;
    padding-top: 80px;

    .InnerContainer {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .InnerContainer {
    width: 91%;
    padding-bottom: 90px;
  }

  .FavouriteGuest {
    flex-direction: column;
    .Left {
      width: 100%;
      order: 2;
      padding-top: 10px;
    }
    .Right {
      order: 1;
      left: 0;
      width: 75%;
    }
  }

  .AccumulatePoints {
    flex-direction: column;
    .Left {
      order: 1;
      left: 30px;
      width: 88%;
    }
    .Right {
      order: 2;
      position: relative;
      bottom: 50px;
      width: 100%;
    }
  }

  .Balance {
    flex-direction: column;
    .Left {
      order: 2;
      position: relative;
      bottom: 100px;
      width: 100%;
    }
    .Right {
      order: 1;
      top: 0;
      left: -10px;
      width: 100%;
    }
  }

  .AccumulatedPoints {
    flex-direction: column;
    padding-top: 0;
    .Left {
      right: -40px;
      width: 95%;
    }
    .Right {
      position: static;
      width: 100%;
    }
  }
}

@include respond-to($mobile) {
  header {
    img {
      width: auto;
      height: auto;
    }
  }

  .Container {
    min-width: $mobile-width;
    width: 94%;
    padding-top: 55px;
    button {
      display: none;
    }
  }

  .InnerContainer {
    width: 94%;
    padding-bottom: 50px;
  }
  .FavouriteGuest {
    flex-direction: column;
    padding-top: 10px;
    .Left {
      order: 2;
      width: 94vw;
      margin-top: 20px;
    }
    .Right {
      order: 1;
      position: relative;
      left: 0;
      width: 300px;
    }
  }

  .AccumulatePoints {
    flex-direction: column;
    margin-top: 20px;
    .Left {
      left: 10px;
      width: 300px;
    }
    .Right {
      position: static;
      width: 100%;
    }
  }

  .Balance {
    flex-direction: column;
    margin-top: 60px;
    .Left {
      order: 2;
      position: static;
      width: 100%;
      margin: 0 auto;
      padding: 0;
    }
    .Right {
      left: -10px;
      width: 300px;
      margin: 0 auto;
      order: 1;
    }
  }

  .AccumulatedPoints {
    flex-direction: column;
    padding-bottom: 105px;
    padding-top: 60px;
    .Left {
      left: 10px;
      width: 300px;
      margin: 0 auto;
    }
    .Right {
      position: static;
      width: 100%;
      margin: 0 auto;
    }
  }
}