@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 85px 45px 40px 45px;
  background-size: 100% 100%;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    border: 1px solid $color_yellow-base;
  }
}

.Title {
  margin-bottom: 25px;
  font-family: 'bebas-bold', sans-serif;
  font-size: 30px;
  line-height: 38px;
  color: $color_white-base;
}

.Street {
  margin-bottom: 11px;
  font-family: 'avante-regular', sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: $color_white-base;
}

.Time,
.Phone,
.Route {
  position: relative;
  padding-left: 22px;

  font-family: 'avante-regular', sans-serif;
  font-size: 13px;
  line-height: 15px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
  }
}

.Time {
  color: $color_yellow-base;
}

.Phone,
.Route {
  a {
    text-decoration: none;
  }
}

.Phone {
  a {
    color: $color_yellow-base;
  }
}

.Route {
  margin-top: 27px;
  a {
    color: $color_white-base;
  }
}

.Time {
  margin-bottom: 8px;
  &::before {
    width: 12px;
    height: 12px;
    background: url('../../../assets/images/mainPage/time.svg') no-repeat;
  }
}

.Phone {
  &::before {
    width: 12px;
    height: 13px;
    background: url('../../../assets/images/mainPage/phone.svg') no-repeat;
  }
}

.Route {
  &::before {
    width: 15px;
    height: 15px;
    background: url('../../../assets/images/mainPage/route.svg') no-repeat;
  }
}

@include respond-to($desktop) {
  .Item {
    padding: 85px 25px 40px 25px;
  }
  .Title {
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 32px;
  }
  .Street {
    margin-bottom: 9px;
    font-size: 12px;
    line-height: 14px;
  }
  .Time,
  .Phone,
  .Route {
    font-size: 10px;
    line-height: 11px;
  }
  .Time,
  .Phone {
    margin-bottom: 6px;
  }
  .Route {
    margin-top: 20px;
  }
}

@include respond-to($tablet) {
  .Item {
    height: 230px;
    margin: 0 5px;
    margin-top: 35px;
    padding: 30px 10px 20px 10px;
  }
  .Title {
    font-size: 20px;
    line-height: 28px;
  }
  .Street {
    font-size: 14px;
    line-height: 17px;
  }
}

@include respond-to($mobile) {
  .Item {
    width: 100%;
    height: 160px;
    margin: 0;
    margin-bottom: 30px;
    padding: 10px 17px 18px 17px;
  }

  .Title {
    margin-bottom: 5px;
    font-size: 35px;
    line-height: 42px;
  }

  .Street {
    margin-bottom: 9px;
    font-size: 12px;
    line-height: 14px;
  }

  .Time,
  .Phone,
  .Route {
    font-size: 10px;
    line-height: 11px;
  }

  .Time {
    margin-bottom: 7px;
  }

  .Route {
    margin-top: 21px;
  }
}