@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Item {
  width: 163px;
  margin-right: 47px;
  margin-bottom: 30px;
  cursor: pointer;
  img {
    width: 100%;
  }
  .Name {
    margin-top: 10px;
    font-family: 'avante-regular', sans-serif;
    font-size: 13px;
    line-height: 19px;
    color: $color_white-base;
  }
}

@include respond-to($desktop) {
  .Item {
    width: 153px;
    margin-right: 25px;
    .Name {
      font-size: 12px;
      line-height: 17px;
    }
  }
}

@include respond-to($tablet) {
  .Item {
    margin-right: 0;
    margin-bottom: 40px;
  }
}

@include respond-to($mobile) {
  .Item {
    width: 133px;
    margin: 0;
    margin-bottom: 44px;
    img {
      width: 100%;
      height: auto;
    }
    .Name {
      margin-top: 13px;
      font-size: 12px;
    }
  }
}

@media (min-width: 320px) and (max-width: 424px) {
  .Item {
    width: 45%;
  }
}