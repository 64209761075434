@import '../../../assets/scss/mixins';

.FranchizeDescription {
  font-family: 'avante-regular', sans-serif;
  font-size: 18px;
  line-height: 1.5;
}

@include respond-to($desktop) {
  .FranchizeDescription {
    font-size: 16px;
  }
}

@include respond-to($tablet) {
  .FranchizeDescription {
    font-size: 16px;
  }
}

@include respond-to($mobile) {
  .FranchizeDescription {
    font-size: 14px;
  }
}