@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.Wrapper {
  position: relative;
  background: url('../../assets/images/mainPage/background2.png');
  background-size: cover;
  background-position: top center;
  overflow: hidden;
}

@include respond-to($tablet) {
  .Wrapper {
    background: $main-bg-color;
  }
}

@include respond-to($mobile) {
  .Wrapper {
    background: $main-bg-color;
  }
}