@font-face {
  font-family: 'avante-regular';
  src: url('../fonts/Avante/AvanteBsRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'bebas-bold';
  src: url('../fonts/BebasNeue/BebasNeueBold.otf');
}

@font-face {
  font-family: 'bebas-book';
  src: url('../fonts/BebasNeue/BebasNeueBook.otf');
}

@font-face {
  font-family: 'bebas-light';
  src: url('../fonts/BebasNeue/BebasNeueLight.otf');
}

@font-face {
  font-family: 'bebas-regular';
  src: url('../fonts/BebasNeue/BebasNeueRegular.otf');
}

@font-face {
  font-family: 'bebas-thin';
  src: url('../fonts/BebasNeue/BebasNeueThin.otf');
}

@font-face {
  font-family: 'vanchrome';
  src: url('../fonts/Vanchrome/vanchromeFront.ttf') format('truetype');
}

@font-face {
  font-family: 'gilroy-bold';
  src: url('../fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'gilroy-heavy-italic';
  src: url('../fonts/Gilroy/Gilroy-HeavyItalic.ttf') format('truetype');
}